/* TypeScript file generated from DocsSentRow.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const DocsSentRowBS = require('./DocsSentRow.bs');

import {DocSentActivity_t as Types_DocSentActivity_t} from '../../src/Types.gen';

import {Mouse_t as ReactEvent_Mouse_t} from '../../src/shims/ReactEvent.shim';

import {style as ReactDOMRe_style} from '../../src/shims/ReactDOMRe.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly activity: Types_DocSentActivity_t; 
  readonly children?: React.ReactNode; 
  readonly className?: string; 
  readonly contactPressed?: (_1:ReactEvent_Mouse_t) => void; 
  readonly hideSpace?: boolean; 
  readonly spacePressed?: (_1:ReactEvent_Mouse_t) => void; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
};

export const $$default: React.ComponentType<{
  readonly activity: Types_DocSentActivity_t; 
  readonly children?: React.ReactNode; 
  readonly className?: string; 
  readonly contactPressed?: (_1:ReactEvent_Mouse_t) => void; 
  readonly hideSpace?: boolean; 
  readonly spacePressed?: (_1:ReactEvent_Mouse_t) => void; 
  readonly style?: ReactDOMRe_style; 
  readonly testID?: string
}> = function DocsSentRow(Arg1: any) {
  const $props = {activity:[(Arg1.activity.target == null ? undefined : [(Arg1.activity.target.contact == null ? undefined : [Arg1.activity.target.contact.name, Arg1.activity.target.contact.imageUrl])]), (Arg1.activity.space == null ? undefined : [Arg1.activity.space.title]), (Arg1.activity.actor == null ? undefined : [Arg1.activity.actor.firstName]), (Arg1.activity.object == null ? undefined : [Arg1.activity.object.name]), Arg1.activity.time, Arg1.activity.verbEnum], children:Arg1.children, className:Arg1.className, contactPressed:Arg1.contactPressed, hideSpace:Arg1.hideSpace, spacePressed:Arg1.spacePressed, style:Arg1.style, testID:Arg1.testID};
  const result = React.createElement(DocsSentRowBS.default, $props);
  return result
};

export default $$default;
