// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Icon from "./Icon.bs.js";
import * as Theme from "../Theme.bs.js";
import * as React from "react";
import * as TestID from "../helpers/TestID.bs.js";
import * as ListItem from "./ListItem.bs.js";
import * as Touchable from "./Touchable.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as DelayedActorTimeDisplay from "./DelayedActorTimeDisplay.bs.js";

require("./DocsSentRow.css");

function DocsSentRow(Props) {
  var testID = Props.testID;
  var className = Props.className;
  var style = Props.style;
  var children = Props.children;
  var activity = Props.activity;
  var contactPressed = Props.contactPressed;
  var match = Props.hideSpace;
  var hideSpace = match !== undefined ? match : false;
  var spacePressed = Props.spacePressed;
  var match$1 = activity[/* target */0];
  var contactName;
  if (match$1 !== undefined) {
    var match$2 = match$1[/* contact */0];
    contactName = match$2 !== undefined ? match$2[/* name */0] : undefined;
  } else {
    contactName = undefined;
  }
  var match$3 = activity[/* space */1];
  var spaceTitle = match$3 !== undefined ? match$3[/* title */0] : undefined;
  var match$4 = activity[/* actor */2];
  var actorName = match$4 !== undefined ? match$4[/* firstName */0] : undefined;
  var match$5 = activity[/* object_ */3];
  var documentName = match$5 !== undefined ? match$5[/* name */0] : undefined;
  var left = React.createElement("div", {
        className: "hui_docs_sent_row_left"
      }, React.createElement(Icon.make, {
            name: /* Document */-188437701,
            light: true,
            size: 15
          }));
  var contact;
  if (contactName !== undefined) {
    var contactName$1 = contactName;
    var tmp = {
      testID: "" + (String(TestID.spaceActivitiesGoToSpaceContact) + (":" + (String(contactName$1) + ""))),
      children: React.createElement("span", {
            className: "hui_docs_sent_row_bold"
          }, contactName$1)
    };
    if (contactPressed !== undefined) {
      tmp.onClick = Caml_option.valFromOption(contactPressed);
    }
    contact = React.createElement("span", undefined, " to ", React.createElement(Touchable.make, tmp));
  } else {
    contact = null;
  }
  var space;
  if (hideSpace || spaceTitle === undefined) {
    space = null;
  } else {
    var tmp$1 = {
      children: React.createElement("span", {
            className: "hui_docs_sent_row_bold"
          }, spaceTitle)
    };
    if (spacePressed !== undefined) {
      tmp$1.onClick = Caml_option.valFromOption(spacePressed);
    }
    space = React.createElement("span", undefined, " for ", React.createElement(Touchable.make, tmp$1));
  }
  var $$document = documentName !== undefined ? React.createElement("div", {
          className: "hui_docs_sent_row_activity_details_text"
        }, React.createElement(Icon.make, {
              name: /* Attachment */-735835133,
              size: 17,
              color: Theme.colors[/* darkGray */8]
            }), React.createElement("span", {
              className: "hui_docs_sent_row_document_text"
            }, " " + (String(documentName) + ""))) : null;
  var tmp$2 = { };
  if (actorName !== undefined) {
    tmp$2.actorName = Caml_option.valFromOption(actorName);
  }
  var tmp$3 = activity[/* time */4];
  if (tmp$3 !== undefined) {
    tmp$2.time = Caml_option.valFromOption(tmp$3);
  }
  var body = React.createElement(React.Fragment, undefined, React.createElement("div", {
            className: "hui_docs_sent_row_activity_text"
          }, "Documents sent", contact, space), React.createElement(DelayedActorTimeDisplay.make, tmp$2), $$document);
  var tmp$4 = {
    left: left,
    body: body,
    children: Belt_Option.getWithDefault(children, null)
  };
  if (testID !== undefined) {
    tmp$4.testID = Caml_option.valFromOption(testID);
  }
  if (className !== undefined) {
    tmp$4.className = Caml_option.valFromOption(className);
  }
  if (style !== undefined) {
    tmp$4.style = Caml_option.valFromOption(style);
  }
  return React.createElement(ListItem.make, tmp$4);
}

var make = DocsSentRow;

var $$default = DocsSentRow;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
